const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
  siteId: '5f9199c4b57d985bfb7156e8',
  enableExperimentalBlocks: true,
  showCardDescription: true,
  metadatas: {
    name: 'Giphar Info',
    colorPrimary: '#75b72f',
    supportEmailAddress: 'contact@gipharinfo.fr'
  },
  analytics: {
    mixpanel: '6048d59329f85125234d3edc2fca682a',
    ga: 'UA-111736601-50',
    sentry:
        'https://b7cb8ec4a3e44547a24d9292f3e4fc2a@o307478.ingest.sentry.io/5479755',
  },
  authMode: ['local'],
  pages: (pager) => {
    return pager
      .remove('radios')
      .remove('podcasts')
      .update('default', {
        title: 'podcasts',
        visibleInMenu: false,
        options: {
          filter: true,
          highlighted: true,
          payload: {
            is: 'podcast'
          }
        }
      })
  }
}, 
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
